export const SERVER = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? "http://localhost:8080"
    : "https://papi.hackernews.xyz";


export async function getRecent(cursor) {
    var q = cursor != null ? `cursor=${cursor}` : "";
    var result = await fetch(`${SERVER}/api/v1/latest?${q}`);
    var json = await result.json();

    return json;
}

export async function getArticle(id) {
    var result = await fetch(`${SERVER}/api/v1/article/${id}`);
    var json = await result.json();

    return json;
}