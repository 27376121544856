import { UNSAFE_LocationContext, useParams } from 'react-router-dom'
function Links() {
    const { id } = useParams();
    const sp = new URLSearchParams(document.location.search);
    const url = sp.get("url");

    window.opener = null;
    document.location.replace(url);
    return (
        <div>

        </div>
    );
}

export default Links;
