import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewsList from './NewsList'
import Layout from './Layout';
import Article from './Article';
import Links from './Links';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<NewsList />} />
          <Route path='page/:cursor' element={<NewsList />} />
          <Route path='articles/:id/:title' element={<Article />} />
          <Route path='links/:id' element={<Links />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
