import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import './NewsList.scss';
import './http'
import { getRecent } from './http';

function doDashes(str) {
    var re = /[^a-z0-9]+/gi; // global and case insensitive matching of non-char/non-numeric
    var re2 = /^-*|-*$/g;     // get rid of any leading/trailing dashes
    str = str.replace(re, '-');  // perform the 1st regexp
    return str.replace(re2, '').toLowerCase(); // ..aaand the second + return lowercased result
}

function newsItem(data) {
    return (
        <div className="item" key={data.id}>
            <div className='inner' style={{ backgroundImage: data.image_url == null ? "none" : `url(//images.weserv.nl/?url=${encodeURIComponent(data.image_url)})` }}>
                <h2>
                    <Link
                        to={`/articles/${data.id}/${doDashes(data.title)}`}
                        state={data}>{data.title}</Link>
                </h2>
            </div>
        </div>
    )
}

function NewsList() {
    const navigate = useNavigate();
    const { cursor } = useParams();
    const [state, setState] = useState({
        items: {
            result: [],
            paging: {
                cursor: null
            }
        },
        previousPages: [null]
    });

    useEffect(() => {
        if (state.items.result.length === 0) {
            loadData(cursor === undefined ? null : cursor);
        }
    })

    async function loadData(cursor) {
        document.title = `Privacy Press`;
        var data = await getRecent(cursor);
        if (data.result.length == 0) {
            var data = await getRecent(null);
        }
        setState(p => {
            return {
                ...p,
                items: data,
            };
        });
    }

    function next() {
        const cursor = state.items.paging.cursor;
        setState(p => {
            p.previousPages.push(cursor);

            return p;
        })

        navigate(`/page/${cursor}`);
        loadData(cursor);
    }

    function previous() {
        var p = state.previousPages;
        let c = p.pop();
        setState(p => {
            return {
                ...p, previousPages: p
            }
        });

        navigate(`/page/${c}`);
        loadData(c);
    }

    return (
        <>
            <header className="App-header">
                <h1>Privacy Press</h1>
            </header>

            <div className='NewsList'>
                <div className="gallery">
                    {state.items.result.map((item) => newsItem(item))}
                </div>
                {state.previousPages.length > 1 ? <button onClick={previous} hi>Back</button> : null}
                <button onClick={next}>Next</button>
            </div>
        </>
    );
}

export default NewsList;
