import { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import createDOMPurify from 'dompurify'
import './Article.scss';
import { getArticle } from './http';
import Showdown, { Converter } from 'showdown';
import 'showdown-youtube';

function Article() {
    const { id } = useParams();
    const passedState = useLocation().state || { description: "", image_url: null };
    const [state, setState] = useState({
        content: null,
        description: passedState.description,
        id: passedState.id,
        image_url: passedState.image_url,
        title: passedState.title,
        updated_at: passedState.updated_at
    })

    useEffect(() => {
        loadArticle();
    });

    function parseDate(input) {
        if (input == null) return "";
        const d = new Date(input);
        return `${d.toDateString()} at ${d.toLocaleTimeString()}`;
    }

    async function loadArticle() {
        if (state.content != null) return;

        Showdown.extension('links', function () {
            return [{
                type: 'lang',
                filter: function (text, converter, options) {
                    const regex = /\[(.*)\]\((.*)\)/gm;
                    const subst = `\[$1\]\(/links/${id}?url=$2\)`;
                    return text.replace(regex, subst);
                }
            }]
        });

        document.title = `${state.title} | Privacy Press`;
        var article = await getArticle(id);
        setState(() => {
            var p = article.result;
            const DOMPurify = createDOMPurify(window)
            const unsanitizedHtml = new Converter({
                tables: true,
                emoji: true,
                ghCodeBlocks: true,
                ghCompatibleHeaderId: true,
                extensions: ['youtube', 'links'],
            }).makeHtml(p.content);

            p.html = DOMPurify.sanitize(unsanitizedHtml);
            document.title = `${p.title} | Privacy Press`;
            return p;
        });
    }

    return (
        <>
            <header className="App-header">
                <h1>{state.title}</h1>
                <div className="st">
                    <span>{parseDate(state.updated_at)}</span>
                </div>
            </header>

            <div className='Article'>
                {state.image_url != null ? <img src={state.image_url} className='banner' /> : null}

                <div className='content' dangerouslySetInnerHTML={{ __html: state.html }}>
                </div>
            </div>
        </>
    );
}

export default Article;
